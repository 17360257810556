import { Content } from '@prismicio/client';
import {
  JSXMapSerializer,
  SliceComponentProps,
  PrismicRichText,
} from '@prismicio/react';
import { PrismicNextLink } from '@/components/Internal/Button';
import Container from '@/components/Unknown/Container';
import { ArrowRightV2 } from '@/components/Unknown/Icons';
import parse from 'html-react-parser';

/**
 * Props for `Counter`.
 */
export type CounterProps = SliceComponentProps<Content.CounterSlice>;

/**
 * Component for "Counter" Slices.
 */

const component: JSXMapSerializer = {
  heading2: ({ children }) => (
    <h2 className="mx-auto text-pretty text-center text-arc-4xl font-bold ~md:~max-w-[42.5rem]/[54.375rem] sm:~lg:~text-arc-7xl/arc-9xl">
      {children}
    </h2>
  ),
  paragraph: ({ children }) => (
    <p className="mx-auto max-w-prose text-pretty text-center font-normal ~lg:~text-arc-base/arc-2xl">
      {children}
    </p>
  ),
};

const formatText = (text: string): string => {
  const words = text.match(/\b[a-zA-Z]+\b/g);
  const combinedWords = words?.join(' ');

  const htmlTag = `<span className="text-[.6em]">${combinedWords}</span>`;

  const formattedText = combinedWords
    ? text.replace(combinedWords, htmlTag)
    : text;

  return formattedText;
};
const Counter = ({ slice }: CounterProps): JSX.Element => {
  return (
    <section
      data-slice-type={slice.slice_type}
      data-slice-variation={slice.variation}
      className="bg-paper text-black ~/md:~py-16/20 md:~md/lg:~py-20/[4.5rem] lg:~lg:~py-[4.5rem]/[5.625rem]"
    >
      <Container>
        <div className="px-0 o-6 md:px-16">
          {slice.primary.show_heading && (
            <PrismicRichText
              field={slice.primary.heading}
              components={component}
            />
          )}
          <PrismicRichText
            field={slice.primary.sub_heading}
            components={component}
          />
        </div>
        <ul className="mx-0 my-11 flex flex-col justify-around gap-10 md:my-20 md:flex-row md:gap-5 lg:mx-16 lg:gap-10">
          {slice.primary.counter_items.map((item, index) => (
            <li key={index} className="w-full o-6">
              <p className="text-pretty text-center text-arc-5xl font-bold md:~md/lg:~text-arc-7xl/arc-5xl desktop:text-arc-8xl">
                {parse(formatText(item.number as string))}
              </p>
              <span className="mx-auto block max-w-fit text-balance text-center text-arc-base lg:~lg:~text-arc-base/arc-lg">
                {item.description}
              </span>
            </li>
          ))}
        </ul>
        {slice.primary.show_button && (
          <div className="flex justify-center md:justify-end">
            <PrismicNextLink
              field={slice.primary.button_link}
              endIcon={<ArrowRightV2 />}
              variant="text"
            >
              {slice.primary.button_label}
            </PrismicNextLink>
          </div>
        )}
      </Container>
    </section>
  );
};

export default Counter;
